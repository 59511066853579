@import '../core-ui/_base'

.chips-container
    margin-left: -.25rem
    
.chip
    display: inline-flex
    flex-direction: row
    background-color: $gray
    border: none
    cursor: default
    height: 23px
    outline: none
    padding: 0
    font-size: 14px
    color: $primary
    font-family:"Open Sans", sans-serif
    white-space: nowrap
    align-items: center
    border-radius: 10px
    vertical-align: middle
    text-decoration: none
    justify-content: center
    margin: .25rem

    .chip-head
        display: flex
        position: relative
        overflow: hidden
        background-color: #32C5D2
        font-size: 1.25rem
        flex-shrink: 0
        align-items: center
        user-select: none
        border-radius: 50%
        justify-content: center
        width: 36px
        color: #fff
        height: 36px
        font-size: 20px
        margin-right: -4px
    
    .chip-content
        cursor: inherit
        display: flex
        align-items: center
        user-select: none
        white-space: nowrap
        padding-left: 12px
        padding-right: 12px
    
    .chip-svg
        color: #999999
        cursor: pointer
        height: auto
        margin: 4px 4px 0 -8px
        fill: currentColor
        width: 1em
        height: 1em
        display: inline-block
        font-size: 24px
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
        user-select: none
        flex-shrink: 0
    
    .chip-svg:hover
        color: #666666
    
