@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500&display=swap')

//Breakpoints
$sm-width: 576px
$md-width: 768px
$lg-width: 992px
$xl-width: 1200px
$xxl-with: 1400px
//Font
$title-font: 'Barlow', sans-serif
$body-font: 'Inter', sans-serif

//Colors
$bg: #EDF2F4
$primary: #1f2336
$secondary: #2b314a

$gray: #8d99ae
$red: #D90429
//Hide Scrollbar

html::-webkit-scrollbar
    width: 0 !important
    -ms-overflow-style: none
    scrollbar-width: none

body
    background-color: $bg
    position: relative
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: $body-font
    color: $primary

img
    display: block

a
    text-decoration: none
    cursor: pointer

h1, h2, h3, h4, h5, h6
    margin: 0
    position: relative
    font-family: $title-font
    font-weight: 600

ul, li, ol
    list-style: none
    padding: 0
    margin: 0
li
    counter-increment: li
    display: list-item
    
button
    cursor: pointer

p
    font-weight: 400
//BASICS
.title-font
    font-family: $title-font
    font-weight: 500
.header-font
    font-family: $title-font
.white-text
    color: $secondary    
.gray-text
    color: $gray