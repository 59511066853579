@import '../../core-ui/_base'

.side-menu
    display: flex
    flex-direction: column
    align-items: flex-end
    background-color: $bg
    height: 100%
    grid-column: span 2

    .logo-section
        height: 40%
        img
            max-width: 100%
            height: 70%
            margin: 0 auto
            object-fit: cover
    .ham-section
        display: flex
        flex-direction: row
        align-items: flex-end
        z-index: 2
        position: fixed
        max-width: 100%
        top: 1%
        right: 3%
        z-index: 100
    .ham-menu
        cursor: pointer
        width: 3.2rem
        height: 3.2rem
    .main-menu
        display: none
    .main-menu.active-menu
        width: 100%
        display: flex
        flex-direction: column
        gap: 1rem
        text-align: center
        width: 100%
        height: 100%
        position: fixed
        top: 0
        background-color: $bg
        z-index: 99
    .menu-links
        display: flex
        flex-direction: column
        gap: 2rem
        text-align: center
    .nav-link
        letter-spacing: 1px
        color: $gray
        font-family: $body-font
        font-size: 1.5rem
        height: 40%
        transition: all 0.4s ease
    .nav-link.active
        text-decoration: none
        color: #fd1056
    .nav-link.main-active
        text-decoration: none
        color: $gray

    .social-media
        display: flex
        flex-direction: row
        gap: 1rem
        margin: 0 auto
        margin-top: 3rem
        img
            width: 1.5rem
            height: 1.5rem
            max-width: 100%
    @media (min-width: $lg-width)
        .main-menu
            display: flex
            position: fixed
            top: 0
            left: 0
            right: 0
            flex-direction: column
            align-items: center
            width: 20%
            z-index: 3
            padding-top: 3rem
        .menu-links
            margin-top: 4rem
        .social-media
            margin-top: 4rem
        .ham-menu
            display: none
        img
            width: 50%
            object-fit: contain
