@use  "../../core-ui/_base"

//MAIN PAGE
.main-page
    display: flex
    flex-direction: column
    gap: 2rem
    margin-top: 20%
    @media (min-width: base.$lg-width)
        grid-column: span 5
        margin-top: 2%

//HEADER
.header
    padding: 1.5rem
    display: flex
    flex-direction: column
    h1
        font-size: 3.8rem
        letter-spacing: 1px
        font-weight: 800
        em
            letter-spacing: 2px

    .download-btn
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        gap: 1rem
        cursor: pointer
        background-color: transparent
        padding: 1rem 0.5rem
        color: base.$primary
        img
            width: 1.5rem
            height: 1.5rem

    a
        background-color: transparent
        border-width: 1px
        border-color: base.$primary
        border-style: solid
        padding: 1rem
        letter-spacing: 1px
        width: 15rem
        max-width: 50%
        text-align: center
        font-family: base.$body-font
        opacity: 0.9
        background: linear-gradient(to right, base.$secondary 50%, transparent 50%)
        background-size: 200% 100%
        background-position: right bottom
        transition: all 0.4s ease

    @media (min-width: base.$sm-width)
        h1
            font-size: 5rem
        p
            font-size: 1.5rem
    @media (min-width: base.$xl-width)
        h1
            font-size: 8rem

.section-container
    padding: 1.5rem
    gap: 2rem

    h2
        font-size: 2.5rem
        letter-spacing: 1px

    h3
        font-size: 2rem
        letter-spacing: 1px

    h4
        font-size: 1.5rem
        letter-spacing: 1px

    @media (min-width: base.$sm-width)
        h2
            font-size: 4rem
    @media (min-width: base.$lg-width)
        display: grid
        grid-template-columns: repeat(2, 1fr)


.section-container
    display: grid
    grid-template-columns: 1fr

.about-main
    display: flex
    flex-direction: column
    padding: 2rem
    grid-column: span 3

.xp
    display: flex
    justify-content: left
    margin-top:1rem
    .container
        min-width: calc(100% - 100px)
        margin-left: 1rem

    .company-img-container
        max-width: 100px

    .company-img
        display: block
        width: 100%
        height: auto

    .company-name
        margin-top: .5rem

    .jobtitle
        display: flex
        justify-content: space-between
        h3
            line-height: 1.4rem

    .task
        margin-top: 1.5rem
        ul
            margin-top:.5rem